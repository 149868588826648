import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={ <Inicio /> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
